import { useGuestStore } from "@/store/guest";

type Props<T> = Parameters<typeof $fetch<T>>;
type AsyncDataOptions<T> = Parameters<typeof useAsyncData<T>>["2"];
export const useGuestRequest = async <T>(
  request: Props<T>["0"],
  options?: Props<T>["1"],
  asyncDataOptions?: AsyncDataOptions<T>,
) => {
  const guestStore = useGuestStore();
  const redirectUrl = useRoute().fullPath;
  let retry = 3;
  const { data, error, execute, pending, refresh, status } =
    await useAsyncData<T>(
      `key-${request}`,
      async () => {
        if (!guestStore.getGuestUsername()) {
          await navigateTo({
            path: "/login",
            query: { redirectUrl },
          });
        }
        return $fetch(request, {
          headers: {
            Authorization: `GuestName:${guestStore.getGuestUsername()}`,
            "Content-Type": "application/json",
          },
          onResponse: async ({ response }) => {
            if (retry === 0) {
              await navigateTo({
                path: "/login",
                query: { redirectUrl },
              });
              return;
            }
            if (response.status === 401) {
              retry--;
              refresh();
            }
          },
          ...options,
        });
      },
      { server: false, ...asyncDataOptions },
    );

  return { data, error, execute, pending, refresh, status };
};
