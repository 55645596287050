import { useAuthStore } from "@/store/auth";
import { useGuestStore } from "@/store/guest";

type Props<T> = Parameters<typeof $fetch<T>>;
type AsyncDataOptions<T> = Parameters<typeof useAsyncData<T>>["2"];
export const useAutoRequest = async <T>(
  request: Props<T>["0"],
  options?: Props<T>["1"],
  asyncDataOptions?: AsyncDataOptions<T>,
) => {
  const authStore = useAuthStore();
  const guestStore = useGuestStore();
  const authenticated = await authStore.isAuthenticated();
  if (authenticated) {
    return await useAuthRequest(request, options, asyncDataOptions);
  }
  if (guestStore.getGuestUsername()) {
    return await useGuestRequest(request, options, asyncDataOptions);
  }
  return useAuthRequest(request, options, asyncDataOptions);
};
